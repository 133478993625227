import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { Navigate } from "react-router-dom";
import config from "../../config";

const TermsAndConditions = ({ currentUser, setCurrentUser }) => {
  const [accepted, setAccepted] = useState(false);
  const [submitted] = useState(false);
  const [externalEmail, setExternalEmail] = useState(false);
  const policyURL = externalEmail
    ? config.EXTERNAL_POLICY_URL
    : config.INTERNAL_POLICY_URL;

  // New variable for the link text
  const policyText = externalEmail
    ? "BambooHR AI Terms of Service"
    : "BambooHR Generative AI Policy";

  const navigate = useNavigate();
  const ACCEPT_TERMS_ENDPOINT = config.VERSION_ENDPOINT + "user/accept-terms";

  // Check if the email address is internal (ending with @bamboohr.com)
  const emailEndsWithBamboohr = currentUser.email.indexOf("@bamboohr.com") > -1;

  useEffect(() => {
    // Set externalEmail to false if the email is internal
    if (emailEndsWithBamboohr === false) {
      setExternalEmail(true);
    }
    // TODO (AIPL-437): Fix warnings, remove this eslint-disable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.email]);

  async function handleSubmit() {
    try {
      if (accepted) {
        let response = await api.put(ACCEPT_TERMS_ENDPOINT);
        currentUser["has-accepted-terms"] =
          response.data["data"]["has-accepted-terms"];
        setCurrentUser(currentUser);
        navigate("/");
      } else {
        alert("You must accept the AI policy before proceeding.");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }

  return submitted ? (
    <Navigate to="/" />
  ) : (
    <div style={{ margin: "0 auto", maxWidth: "800px" }}>
      <div id="introsection">
        <h1 style={{ textAlign: "center" }}>Applicable Terms</h1>
        <p style={{ textAlign: "center" }}>
          Use of this application is governed by the{" "}
          <a href={policyURL} target="_blank" rel="noreferrer">
            {policyText}
          </a>
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <label style={{ margin: "20px", textAlign: "center" }}>
            <input
              type="checkbox"
              style={{ marginRight: "10px" }}
              checked={accepted}
              onChange={() => setAccepted(!accepted)}
            />
            I have read and agree to abide by the above policy
          </label>
          <button
            onClick={handleSubmit}
            style={{ padding: "10px 20px", fontSize: "18px" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
