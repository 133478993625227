import { createContext, useReducer } from "react";
import reducer from "./AuthReducer";

const initialState = {
  // currentUser must be object so JSON string must be parsed into JS object
  currentUser: null,
};

export const AuthContext = createContext(initialState);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
