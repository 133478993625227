import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useState } from "react";
import CodeBlock from "./codeBlock/CodeBlock";
const BotResponse = ({
  response,
  chatLogRef,
  hasPrinted,
  setHasPrinted,
  sources,
}) => {
  const [botResponse, setBotResponse] = useState("");
  const [isPrinting, setIsPrinting] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [displaySources, setDisplaySources] = useState(null);

  useEffect(() => {
    let index = 1;

    let msg = setInterval(() => {
      setIsButtonVisible(true);

      if (!isPrinting) {
        // if isPrinting is false, clear interval and return
        setBotResponse(response);
        setIsButtonVisible(false);
        setDisplaySources(renderSources());
        clearInterval(msg);
        return;
      }
      if (hasPrinted) {
        stopPrinting();
        setIsButtonVisible(false);
        setDisplaySources(renderSources());
        setBotResponse(response);
        setHasPrinted(false);
        return;
      }

      setBotResponse(response.slice(0, index));
      if (index >= response.length) {
        clearInterval(msg);
        setIsButtonVisible(false);
      }
      index = index + 10;

      if (chatLogRef !== undefined) {
        chatLogRef.current?.scrollIntoView({
          behavior: "auto",
          block: "end",
        });
      }
    }, 50);

    setDisplaySources(renderSources());
    return () => clearInterval(msg); // clear interval on component unmount
    // TODO (AIPL-437): Fix warnings, remove this eslint-disable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatLogRef, response, isPrinting]);

  // Render sources as hyperlinks
  const renderSources = () => {
    if (sources == null || sources.length === 0) {
      return null;
    }

    return (
      <>
        <div style={{ marginTop: "20px" }}>Sources:</div>
        {sources.map((item, index) => (
          <div key={index} id="statusMessage" ref={chatLogRef}>
            <ul>
              <li>
                {item.source ? (
                  <a
                    href={item.source}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.title}
                  </a>
                ) : (
                  // Don't show as clickable URL for null sources
                  <p>{item.title}</p>
                )}
              </li>
            </ul>
          </div>
        ))}
      </>
    );
  };

  const stopPrinting = () => {
    setIsPrinting(!isPrinting);
    setDisplaySources(renderSources());
    setTimeout(() => {
      chatLogRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 300);
  };

  return (
    <>
      <ReactMarkdown
        components={{
          code: CodeBlock,
        }}
      >
        {botResponse}
      </ReactMarkdown>
      {isButtonVisible && isPrinting && (
        <button className="stop-message" onClick={stopPrinting}>
          Finish Printing
        </button>
      )}
      {!isButtonVisible && displaySources}
    </>
  );
};

export default BotResponse;
